import {
    JsonldOrganization,
    JsonldProductItemList,
    JsonldQuestionList,
    TOrganizationData,
    TProductData,
    TQuestionData,
} from '@dengigroup/seo-jsonld-markup';
import { FC } from 'react';

import { TSlider } from '@/types/sliders';

import { Footer } from '@/widgets/footer';
import { Header } from '@/widgets/header';

import { Categories } from '@/features/categories';

import { PopularArticles, TArticle } from '@/entities/article';
import { Announcement, HowToUse, Benefits } from '@/entities/info';
import { HeadMeta } from '@/entities/meta-seo';
import { PopularProducts, TRentProduct } from '@/entities/product';
import { QuestionsList, TQuestion } from '@/entities/question';

import { ARTICLES_URL, BASE_URL, QUESTIONS_URL } from '@/shared/constants/paths';
import { Layout } from '@/shared/ui/layout';
import { LinkPageSection } from '@/shared/ui/link-page-section';
import { PageSection } from '@/shared/ui/page-section';
import { Slider } from '@/shared/ui/slider';

type TProps = {
    title: string;
    description: string;
    articles: TArticle[];
    sliders: TSlider[];
    questions: TQuestion[];
    popularProducts: TRentProduct[];
};

export const MainPage: FC<TProps> = ({ articles, questions, sliders, description, title, popularProducts }) => {
    const organization: TOrganizationData = {
        nameCompany: 'ТОО "RENTAL SYSTEM"',
        phone: '+7 (700) 077-55-77',
        addressCountry: 'Казахстан',
        addressLocality: 'город Костанай',
        addressRegion: 'Костанайская область',
        streetAddress: 'улица В.Чкалова, дом 9, н.п. 62',
        postalCode: '110000',
    };

    const seoLinkedProducts: TProductData[] = popularProducts?.map((product) => {
        return {
            price: product.prices?.priceForThirtyDays?.toString() ?? '',
            image: product.photos?.[0]?.image ?? '',
            name: product.name,
            priceCurrency: 'KZT',
            inStock: product.status === 'active',
        };
    });

    const seoLinkedQuestions: TQuestionData[] = questions.map((faq) => {
        return {
            question: faq.question,
            answer: faq.answer,
        };
    });

    return (
        <Layout header={<Header />} footer={<Footer />}>
            <HeadMeta title={title} description={description} />
            <JsonldOrganization data={organization} />
            {popularProducts?.length > 0 && <JsonldProductItemList data={seoLinkedProducts} url={BASE_URL} />}
            {questions.length > 0 && <JsonldQuestionList data={seoLinkedQuestions} url={BASE_URL} />}
            <Layout.Content>
                <Slider sliders={sliders} />
                <HowToUse />
                <Categories />
                <Announcement />
                {popularProducts?.length > 0 && (
                    <PageSection title="Самые популярные 🔥">
                        <PopularProducts idSlider="popular" products={popularProducts} />
                    </PageSection>
                )}
                <Benefits />
                <PageSection
                    title="Самые частые вопросы"
                    linkComponent={<LinkPageSection title="Все вопросы" href={QUESTIONS_URL} />}
                >
                    <QuestionsList questions={questions} />
                </PageSection>
                <PageSection
                    title="Полезные материалы"
                    linkComponent={<LinkPageSection title="Смотреть все" href={ARTICLES_URL} />}
                >
                    <PopularArticles articles={articles} />
                </PageSection>
            </Layout.Content>
        </Layout>
    );
};
