import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import type { NextPage, NextPageContext } from 'next';
import React from 'react';

import { wrapperReduxToolkit } from '@/store';
import { loadWithPopularProducts } from '@/store/utils/load-with-popular-products';
import { TSlider } from '@/types/sliders';

import { MainPage } from '@/pages/main-page';

import { TArticle, getArticles } from '@/entities/article';
import { getMetaDataPage } from '@/entities/meta-seo';
import { TRentProduct } from '@/entities/product';
import { TQuestion, getQuestions } from '@/entities/question';

import { getSliders } from '@/shared/api';
import { BASE_URL } from '@/shared/constants/paths';
import { NOT_FOUND_PAGE, SERVER_ERROR, STATUS_CODE_OK } from '@/shared/constants/settings';

type TProps = {
    title: string;
    description: string;
    articles: TArticle[];
    sliders: TSlider[];
    questions: TQuestion[];
    statusCode: number;
    popularProducts: TRentProduct[];
};

const Page: NextPage<TProps> = ({ articles, questions, sliders, description, title, popularProducts }) => {
    return (
        <MainPage
            articles={articles}
            questions={questions}
            sliders={sliders}
            description={description}
            title={title}
            popularProducts={popularProducts}
        />
    );
};

Page.getInitialProps = wrapperReduxToolkit.getInitialPageProps(
    ({ dispatch, getState }) =>
        async (context: NextPageContext) => {
            const { res, locale } = context;
            let statusCode = STATUS_CODE_OK;

            const { popularProducts } = await loadWithPopularProducts({
                getState,
                dispatch,
                locale: locale as string,
                context,
            });

            try {
                const response = await Promise.all([
                    getArticles(1, locale, undefined, true),
                    getQuestions(locale as string, true),
                    getSliders(locale),
                    getMetaDataPage(BASE_URL, locale as string),
                ]);

                return {
                    articles: response[0].data,
                    questions: response[1].data,
                    sliders: response[2].data,
                    popularProducts,
                    ...response[3],
                };
            } catch (err) {
                const error = err as AxiosError;
                statusCode = error.response?.status === NOT_FOUND_PAGE ? NOT_FOUND_PAGE : SERVER_ERROR;
                console.error('MainPage.getInitialProps error', error);
                Sentry.captureException(error);
            }

            if (res) {
                res.statusCode = statusCode;
            }

            return { statusCode };
        },
);

export default Page;
