import Image from 'next/image';

import { useAppSelector } from '@/store/hooks';
import girlOnChair from 'public/static/images/girl-on-chair.png';

import { useTranslate } from '@/shared/hooks/use-translate';
import { LinkButton } from '@/shared/ui/link-button';

import * as Styled from './styled';

export const Announcement = () => {
    const { t } = useTranslate();
    const cityUser = useAppSelector((state) => state.user.cityUser);

    return (
        <Styled.Wrapper>
            <Styled.Section $alignItems="flexStart" width="60%">
                <Styled.Title>{t('У Вас появилась возможность выкупить товары после аренды 🙌')}</Styled.Title>
                <Styled.Line>
                    {t(
                        'Это услуга позволяет приобрести товары, которые вы арендовали. И товар становится полностью вашим!',
                    )}
                </Styled.Line>
                <Styled.Line>
                    {t(
                        'Это удобное и выгодное решение для тех, кто хочет пользоваться нужными вещами, не оплачивая полную стоимость сразу.',
                    )}
                </Styled.Line>
                <Styled.Line>{t('Сэкономьте свой бюджет и пользуйтесь любимыми вещами уже сегодня!')}</Styled.Line>
                <Styled.ButtonBox>
                    <LinkButton variant="primary" slug={`/${cityUser?.slug}`}>
                        {t('Выбрать товар')}
                    </LinkButton>
                    <LinkButton variant="primary" outlined slug="/buyout-information">
                        {t('Подробнее')}
                    </LinkButton>
                </Styled.ButtonBox>
            </Styled.Section>

            <Styled.Section $alignItems="center" width="40%">
                <Image src={girlOnChair} height={230} alt="girl on chair" />
            </Styled.Section>
        </Styled.Wrapper>
    );
};
